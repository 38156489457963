.App {
  text-align: center;
}
.App,
#root,
.ar-app {
  width: 100%;
  height: 100%;
}
.ar-app {
  max-width: 100vw;
  overflow: hidden;
}
.ar-app .cont-ar {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}
@font-face {
  font-family: "montserrat-regular";
  src: url(./assets/fonts/Montserrat-Regular.ttf);
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "montserrat-bold";
  src: url(./assets/fonts/Montserrat-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "montserrat-medium";
  src: url(./assets/fonts/Montserrat-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "montserrat-semibold";
  src: url(./assets/fonts/Montserrat-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "playfair-italic";
  src: url(./assets/fonts/PlayfairDisplay-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "montserrat-semibold-italic";
  src: url(./assets/fonts/Montserrat-SemiBoldItalic.ttf);
}

.text-italic-semi {
  font-family: "montserrat-semibold-italic";
}
.italic-title {
  font-size: 30px;
  margin-bottom: 0px;
  color: #353535;
}
.info-box {
  padding: 0px 50px;
}
.info-box p {
  margin-top: 12px !important;
  margin-bottom: 25px !important;
}
.text-link {
  text-decoration: underline;
  color: #353535;
}
.italic-box {
  margin-top: 320px !important;
  padding: 10px;
  max-width: 400px;
  color: #000000;
  font-size: 30px;
  line-height: 30px;
  background-color: #ffffff;
}
.logo-header {
  margin-bottom: 80px !important;
}

.homepage .header {
  margin-bottom: 0px;
  -webkit-box-shadow: inset 0px 166px 162px -86px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset 0px 166px 162px -86px rgba(0, 0, 0, 0.75);
  box-shadow: inset 0px 166px 162px -86px rgba(0, 0, 0, 0.75);
}
.homepage .title {
  font-size: 52px;
  line-height: 65px;
  color: #ffffff;
  margin-bottom: 120px;
}
.homepage .navbar {
  background-color: transparent;
}
.navbar-toggler-icon {
  background-image: url("./assets/icone/icon-menu.png") !important;
  margin: 20px;
}
.cartolina {
  background-image: url("./assets/header/cartolina.jpg") !important;
  background-size: cover;
  min-height: 550px;
}
.text-link:hover {
  color: #000000 !important;
}
.text-bold {
  font-family: "montserrat-bold";
}
.text-medium {
  font-family: "montserrat-medium";
}
.text-regular {
  font-family: "montserrat-regular";
}
.text-semibold {
  font-family: "montserrat-semibold";
}
.menu a {
  font-family: "montserrat-bold";
  font-size: 14px;
  padding: 0px 25px;
}
.social-icon img {
  width: 18px;
}

p,
button {
  font-family: "montserrat-regular";
  font-size: 15px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.btn-primary.transparent {
  border-radius: 0px;
  background-color: transparent;
  border: 1px solid #fff;
}
.card {
  color: #fff;
  border: 0px;
  border-radius: 0px;
}
.card-img-top {
  border-radius: 0px;
}
img.cardImage {
  object-fit: cover;
  min-height: 100%;
  max-width: 100%;
}
.btn-primary:hover {
  background-color: transparent;
  border-color: #fff;
}
.size-16 {
  font-size: 16px !important;
  line-height: 19px;
}
.size-18 {
  font-size: 18px !important;
  line-height: 22px;
}
.text-color {
  color: #353535;
}
#luoghi-pescatori .tappa.row:after {
  border-right: dashed 3px #2e92ba;
}
#luoghi-cavalieri .tappa.row:after {
  border-right: dashed 3px #cd2c27;
}
#luoghi-conte .tappa.row:after {
  border-right: dashed 3px #418c41;
}
.tappa.row:after {
  height: 100px;
  width: 50%;

  content: "";
}
.footer-c {
  padding-top: 50px;
  padding-bottom: 70px;
  background-color: #f7f7f7;
}
.coming-soon .footer-c {
  padding-top: 10px;
  padding-bottom: 20px;
  background-color: #f7f7f7;
}
.gray-background {
  background-color: #f6f6f6;
}
.black-background {
  background-color: #000000;
}
.green-background {
  background-color: #418c41;
}
.green-text,
#luoghi-conte .dettaglio-luoghi .text-bold {
  color: #418c41;
}
.blue-background {
  background-color: #2e92ba;
}
.blue-text,
#luoghi-pescatori .dettaglio-luoghi .text-bold {
  color: #2e92ba;
}
.red-background {
  background-color: #cd2c27;
}
.red-text,
#luoghi-cavalieri .dettaglio-luoghi .text-bold {
  color: #cd2c27;
}
.percorsi {
  max-width: 375px;
  margin: 0px 10px;
}
.percorsi p {
  padding: 25px;
  color: #ffffff;
  font-size: 24px;
  margin: 0px;
}
.homepage .card-body {
  padding: 0px 50px 50px 50px;
  min-height: 300px;
}
.homepage .card-title {
  margin: 30px 0px 25px 0px;
}
.homepage .card-text {
  min-height: 120px;
}
.percorsi-container {
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 120px;
}
.slider-row {
  margin-bottom: 40px;
}
.no-padding {
  padding: 0px;
}
.descrizione {
  padding: 90px 70px;
  color: #ffffff;
}
.descrizione:before {
  background-image: url("./assets/icone/long-percorso.png");
  background-repeat: no-repeat;
  display: block;
  background-size: contain;
  width: 19px;
  height: 181px;
  content: " ";
  position: absolute;
  top: 70px;
  left: 30px;
}
.footer-c p,
.descrizione p {
  margin: 0px;
  text-align: left;
}
.footer-c .info {
  font-size: 13px;
}
.footer-c .info a {
  text-decoration: none;
  font-size: 13px;
}
.footer-c a {
  font-size: 15px;
  color: #000000;
  text-decoration: underline;
}
.icon-circle {
  width: 38px;
  margin-left: 15px;
}
.close-area {
  padding: 20px;
}
.info-desc {
  padding-top: 40px;
}
.info-desc .text-bold {
  font-size: 18px;
  line-height: 22px;
}
.tappa {
  text-align: left;
}
.ar-box {
  padding: 40px 50px;
  background-color: #353535;
}
.nav-link {
  color: white !important;
}

.navbar {
  background-color: black;
  padding: 25px 0px;
}

.padding-row {
  padding: 60px 0px 0px 0px;
}
.img-over {
  position: relative;
  top: 50px;
}
.text-box {
  padding: 50px 50px 70px 130px;
}

.header img {
  max-width: 100%;
  max-height: 750px;
}
.text-center {
  text-align: center;
}
.content-center {
  margin: 0px auto;
}
.text-left {
  text-align: left;
}
.header {
  padding: 0px;
  margin-bottom: 50px;
}

.accordion .card-header .btn-link {
  color: #1e1e1e;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  font-family: "montserrat-bold";
}

.accordion .card-header {
  background-color: #ffffff;
  margin-bottom: 0px !important;
  border-bottom: 1px solid #979797;
}
.open {
  background-color: #fafafa !important;
  border: none !important;
}
.accordion .card button {
  padding: 0px;
}
.accordion {
  margin-top: 70px;
  margin-bottom: 150px;
}
.card-body.open {
  border-bottom: 1px solid #979797 !important;
}
div.collapse.show {
  font-size: 15px;
  color: #1e1e1e;
}
h1 {
  margin-bottom: 25px;
}
.dettaglio-luoghi {
  padding: 40px 30px;
}

.white-text {
  color: #ffffff;
}
.portfolio {
  margin-top: 70px;
}
.form-control {
  border-radius: 0;
  background-color: #f7f7f7;
  color: #707070;
  border: 0px;
}
.portfolio__labels {
  text-align: center;
  line-height: 110%;
}
.portfolio__labels .actived {
  box-sizing: border-box;
  border: 2px solid #d1d1d1;
  border-radius: 17.5px;
  padding: 5px 10px;
}
.portfolio__labels > span {
  margin-left: 30px;
  margin-right: 30px;
}
.portfolio__labels span {
  cursor: pointer;
  color: black;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  padding: 5px;
  vertical-align: text-bottom;
  margin-bottom: 10px;
}
.portfolio__labels .x_item {
  color: #707070;
  line-height: 90%;
}

.filter-out {
  display: none;
}
.portfolio__container {
  margin-top: 60px;
}
.portfolio_figure {
  min-height: 300px;
  -webkit-box-shadow: inset 0px -33px 26px -11px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset 0px -33px 26px -11px rgba(0, 0, 0, 0.75);
  box-shadow: inset 0px -33px 26px -11px rgba(0, 0, 0, 0.75);
}
.portfolio_figure:hover {
  background-color: #000000 !important;
  background-image: none !important;
}
.portfolio_figure_name,
.slider_name {
  pointer-events: none;
  color: #ffffff;
  text-align: left;
  font-size: 28px;
  line-height: 34px;
  position: absolute;
  bottom: 20px;
  left: 15px;
  font-weight: bold;
  font-family: "montserrat-bold";
}
.text-italic {
  font-family: "playfair-italic";
}
.portfolio_figure.show .text-italic,
.flex-slider .text-italic {
  pointer-events: none;
  display: block;
  padding-top: 20px;
  padding-left: 15px;
  font-size: 24px;
  color: #ffffff;
}
.portfolio_figure .arrow {
  display: none;
}
.portfolio_figure.show .portfolio_figure_name,
.slider_name {
  position: initial;
  padding-left: 10px;
}
.flex-slider .slider_name,
.flex-slider .text-italic {
  padding-left: 30px;
}
.portfolio_figure.show .arrow,
.flex-slider .arrow {
  display: block;
  position: absolute;
  bottom: 0px;
  right: 0px;
  margin-right: 30px;
  margin-bottom: 30px;
}
.portfolio_figure .pin {
  display: none;
}
.label-img img {
  max-width: 345px;
  margin: 5px 0px;
}
.page-percorsi .link-fuori {
  display: none !important;
}
.portfolio_figure.show .pin,
.flex-slider .pin {
  display: block;
  position: absolute;
  bottom: 0px;
  left: 0px;
  margin-left: 30px;
  margin-bottom: 30px;
}
.flex-slider img {
  object-fit: none;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100%;
}
.portfolio_figure.show .pin img,
.flex-slider .pin img {
  width: 19px;
  margin: 0px 5px;
  display: inline;
}
.portfolio_figure .text-italic {
  display: none;
}

.subtitle {
  font-size: 24px;
  margin-top: 20px;
  line-height: 29px;
}
.flex-slider {
  display: flex !important;
  height: 340px;
}
.ar-row {
  margin-bottom: 80px;
}
.ar-page .box {
  padding: 20px 90px;
}
.box-percorso {
  padding: 70px 120px 30px 120px;
}
.descrizione .text-semibold {
  margin-bottom: 10px;
}
.box-percorso button {
  padding: 10px 30px;
}
.box-percorso h2 {
  font-size: 36px;
  margin-bottom: 25px;
}
.logo-info_1,
.logo-info_2 {
  position: absolute;
  right: 15px;
  width: 12px;
}
.card-header.open .logo-info_1,
.card-header .logo-info_2 {
  display: none !important;
}
.card-header.open .logo-info_2 {
  display: inline !important;
}
.boxed-info {
  font-size: 36px;
  padding: 40px;
  color: #353535;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
}
.box-percorso p {
  font-size: 18px;
  line-height: 22px;
}
.info-icon {
  width: 20px;
  margin-right: 15px;
}
.luogo-sidebar {
  padding-left: 30px;
  padding-top: 50px;
}
.luogo-sidebar .label {
  font-size: 18px;
}
.icon-close img {
  width: 21px;
}
.language img {
  width: 24px;
  margin-left: 10px;
  vertical-align: middle;
}
.icon-close {
  display: none;
  margin-bottom: 40px;
  margin-top: 20px;
}
.icon-close img {
  cursor: pointer;
}
.big {
  font-size: 42px;
}
.all-center {
  margin: auto;
}
.title {
  font-size: 42px;
  line-height: 52px;
  text-transform: uppercase;
}
.label-img {
  border-bottom: solid 1px #e3e3e3;
  padding-bottom: 30px;
}
.breadcrumbs,
.breadcrumbs a {
  font-size: 13px;
  color: #353535;
  margin: 20px 0px;
  font-family: "montserrat-regular";
}
.footer-c .btn-primary.transparent,
form .btn-primary.transparent {
  font-size: 15px;
  color: #000000;
  border: 1px solid #000000;
  text-transform: uppercase;
  width: 230px;
  height: 50px;
  font-weight: 500;
}
.logo-top {
  width: 250px;
  margin: 20px 0px;
}
.coming-soon .italic-box {
  margin-top: 5% !important;
}
@media screen and (min-width: 1024px) {
  .wip {
    float: left;
    margin-right: 15px;
  }
  .coming-soon .title {
    margin-bottom: 7%;
  }

  .header.coming-soon {
    background-size: cover;
    min-height: 100vh;
  }
  .navbar-brand {
    margin-left: 100px;
    margin-right: 150px;
  }
}

@media screen and (max-width: 1024px) {
  .header.coming-soon {
    background-size: cover;
    min-height: 100vh;
  }

  .logo {
    margin-top: 35px !important;
  }
  .coming-soon .title {
    margin-top: 20px;
    font-size: 32px;
    line-height: 34px;
  }
  .coming-soon .italic-box {
    font-size: 22px;
    max-width: 290px;
  }
  .text-box {
    padding: 15px !important;
  }
  .portfolio__labels > span {
    display: block;
  }
  .italic-title {
    font-size: 22px;
  }
}
@media screen and (max-width: 992px) {
  .nav-item {
    margin: 15px 0px;
  }
  .navbar-toggler-icon {
    display: block;
    cursor: pointer;
  }
  .ar-link {
    margin: -30px auto 0px auto;
    width: 200px;
  }
  .ar-link a {
    border: 1px dashed #ffffff;
    padding: 15px;
  }
  .ar-link img {
    background-color: #000000;
    padding: 7px;
    position: relative;
    top: 19px;
  }
  .hide {
    display: none;
  }
  .hide-992 {
    display: block;
  }
  .hide-992 .social-icon {
    display: inline;
  }
  .line {
    height: 2px;
    width: 34px;
    border: 1px solid #ffffff;
    margin: 30px auto !important;
  }
  .descrizione p {
    margin: auto;
    width: 150px;
  }

  .descrizione:before {
    left: 25% !important;
  }
  .navbar.resp-open {
    background-color: #000000;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1000;
    overflow: scroll;
  }
  div.collapse.show {
    height: 100%;
  }
  .homepage .menu.row {
    width: 100%;
  }
  .homepage .navbar-brand {
    margin-left: 20px;
  }
  .homepage .resp-open {
    margin: 0px 15px;
  }
}

@media screen and (min-width: 992px) {
  .navbar-toggler-icon,
  .ar-link {
    display: none;
  }
  .language {
    margin-left: 20px;
  }
  .language {
    font-size: 12px !important;
    font-family: "montserrat-semibold" !important;
  }
  .hide {
    display: block;
  }
  .hide-992 {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .biglietto .col-md-6 {
    text-align: center !important;
    margin: 10px 0px;
  }

  .big {
    font-size: 24px;
  }
  .tappa.row {
    text-align: center;
  }
  .tappa.row.pari,
  .order-row {
    display: flex;
    flex-direction: column;
  }
  .tappa.row.pari > div:first-child,
  .order-row > div:first-child,
  #row-cavalieri > div:nth-child(2) {
    order: 2;
  }
  .tappa.row.pari > div:nth-child(2),
  .order-row > div:nth-child(2) {
    order: 1;
  }
  .tappa.row.pari:after {
    order: 3;
  }
  .tappa.row .dettaglio-luoghi {
    padding: 20px;
  }
  .footer-c p,
  .footer-c div {
    text-align: center !important;
    margin: 0px auto;
  }

  .flex-slider {
    display: inline-block !important;
  }
  .flex-slider > div > img,
  .img-over {
    width: 100% !important;
  }
  .flex-slider div:nth-child(2) {
    height: 300px;
  }

  .img-over {
    position: initial;
  }
  .ar-page .box {
    padding: 10px;
  }
  .box-percorso {
    text-align: center !important;
    padding: 20px 10px;
  }
}

@media screen and (max-width: 600px) {
  .navbar-brand img {
    max-width: 180px !important;
  }
}

@media screen and (max-width: 400px) {
  .label-img img {
    max-width: 100%;
    width: 100%;
  }
}

#filtro {
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  width: 240px;
  position: absolute;
  bottom: 15px;
  text-align: left;
  padding: 15px;
  height: 45px;
  left: 15px;
}

.arrow-filtro {
  width: 12px;
  text-align: right;
  cursor: pointer;
}
#filtro p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
}
.filtro-list {
  border-top: 1px solid #e6e6e6;
  height: 40px;
  margin: auto;
  cursor: pointer;
}
.open-filter {
  height: auto !important;
}
.filtro-list span img {
  margin-right: 10px;
}

.ar-content #filtro {
  display: none;
}
.ar-content {
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 900;
  background-color: #ffffff;
  text-align: left !important;
  position: absolute;
  overflow-x: hidden;
  overflow-y: scroll;
}
.ar-content .close-map,
.ar-content #filtro {
  display: none;
}
.ar-content .map-div {
  width: 100%;
  height: 300px;
  position: unset;
}

.ar-content h1 {
  font-size: 32px;
  line-height: 34px;
}
.ar-content-info {
  padding: 10px;
}
.ar-content .header-img {
  width: 100%;
}
.ar-content .descr {
  color: #353535;
  font-size: 15px;
  line-height: 22px;
  padding: 0px;
}
.ar-content .nav-tabs {
  border: none;
}
.ar-content .nav-tabs .nav-link {
  font-family: "montserrat-bold";
  line-height: 22px;
  font-size: 15px;
  color: #353535 !important;
}
.ar-content .nav-tabs .nav-link.active {
  color: #353535 !important;
  border-bottom: 2px solid #e20019;
  border-top: none;
  border-left: none;
  border-right: none;
}
.ar-content hr {
  color: #e6e6e6;
}
.check,
.icon-filter {
  width: 15px;
  text-align: right;
  cursor: pointer;
}

/** error **/
.error-container {
  background-color: rgba(205, 44, 39, 0.7);
  border-radius: 0 0 10px 10px;
  top: 0;
  left: 0;
  padding: 1.25rem;
  color: #ffffff;
}

/** anteprima **/
#anteprima {
  border-radius: 10px 10px 0 0;
  background-color: #ffffff;
  bottom: 0;
  left: 0;
  padding: 11px 1rem 1.25rem 1rem;
  position: absolute;
  width: 100%;
  z-index: 800;
}
#content-anteprima {
  background-color: #ffffff;
  z-index: 800;
  margin-top: 25px;
}
.image-anteprima {
  float: left;
  width: 50%;
}
.descr-anteprima {
  float: right;
  width: 50%;
  color: #000000;
  text-align: left;
}
.distance {
  padding-left: 10px;
}
.swipe-icon {
  height: 3px;
  margin: auto;
  width: 31px;
  border-radius: 1.5px;
  background-color: #d8d8d8;
}
.icon-info {
  width: 10px;
  margin: 0px 2px;
}
.ar-content .info-mappa-cat {
  font-size: 12px;
  margin-left: 12px;
  font-family: "montserrat-bold";
}
.swipe {
  width: 100%;
  padding: 11px 0px;
  margin-bottom: 15px;
  clear: both;
  border-radius: 10px 10px 0 0;
  background-color: #f7f7f7;
  position: absolute;
  top: 0;
  left: 0;
}
.ar-content .icon-info {
  width: 10px;
}
.ar-content .category-cont {
  margin-bottom: 10px;
}
.info-mappa-cat {
  font-size: 10px;
  margin-left: 10px;
  font-family: "montserrat-bold";
}
/** content **/
.img-gallery {
  width: 100%;
  margin-top: 10px;
  cursor: pointer;
}
.foto-section {
  margin-top: 30px;
  margin-bottom: 50px;
}

.close-color {
  width: 36px;
  position: absolute;
  cursor: pointer;
  right: 10px;
  top: 10px;
}

.read-more {
  color: #e20019;
  font-size: 15px;
  line-height: 22px;
  cursor: pointer;
}

/**
 * Custom CSS 2022
 */
.ar-app-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

/*
 * Borgo Mansory
 */
.mansory-row-one > div:nth-child(1),
.mansory-row-two > div:nth-child(1) {
  width: 36%;
  padding-left: 0px;
}

.mansory-row-one > div:nth-child(2) {
  width: 37%;
  padding: 0px;
}

.mansory-row-two > div:nth-child(2) {
  width: 25%;
  padding: 0px;
}

.mansory-row-one > div:nth-child(3) {
  width: 27%;
  padding-right: 0px;
}

.mansory-row-two > div:nth-child(3) {
  width: 39%;
  padding-right: 0px;
}

.mansory-row-one div,
.mansory-row-two div {
  flex-grow: unset;
  flex-basis: auto;
}

.mansory-row-one img,
.mansory-row-two img {
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 300px;
}

.mansory-row-two {
  margin-bottom: 120px;
  margin-top: 15px;
}

@media (max-width: 767px) {
  .mansory-row-one div,
  .mansory-row-two div {
    width: 100% !important;
    padding: 0 15px !important;
    margin-bottom: 15px;
  }
}
